<template lang="">
  <body>
    <div class="main">
      <div class="vector">
        <h1>MENTIONS LEGALES</h1>
      </div>
      <div class="editeur_et_hebergeur">
        <h2>EDITEUR DU SITE</h2>
        <p>Le présent site, accessible à l’adresse s-ecrire.com, est exploité par l’entreprise individuelle Florence Dubois, immatriculée au Registre du Commerce et des Sociétés de Clermont-Ferrand sous le numéro 527 906 440 00039 et dont le siège social est situé 4 avenue Valery Giscard D’estaing, 63400 Chamalières.</p>
        <h2>HEBERGEUR</h2>
        <ul>
          <li>Hébergeur : HEROKU Inc. – 650 7th Street San Francisco, CA USA</li>
        </ul>
      </div>
    </div>
  </body>
</template>
