<template>
  <div class="tab-content" v-show="title == selectedTitle" :class="{ selected: title == selectedTitle }" :style="{ backgroundColor: backgroundColor }">
    <slot></slot>
  </div>
</template>

<script>
import { inject } from 'vue'

export default {
  props: {
    title: String,
    backgroundColor: String,
  },
  setup() {
    const selectedTitle = inject('selectedTitle')

    return {
      selectedTitle,
    }
  }
}
</script>

<style scoped>
  .tab-content {
    width: 100%;
    min-height: 400px;
    padding: 20px;
    border-radius: 0 0 80px 80px;
  }
</style>
