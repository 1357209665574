// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
console.log('Vite ⚡️ Rails')

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

console.log('Visit the guide for more information: ', 'https://vite-ruby.netlify.app/guide/rails')

// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'

// Import Create Vue App
import { createApp } from "vue";

// Import App Component
import App from "../components/App.vue";
import UserFooter from "../components/UserFooter.vue";
import UserHeader from "../components/UserHeader.vue";
import AtelierPage from "../components/AtelierPage.vue"
import MentionLegalPage from "../components/MentionLegalPage.vue"
import TabsWrapper from "../components/TabsWrapper.vue"
import AtelierTab from "../components/AtelierTab.vue"


// Create Vue App
const app = createApp(App).mount("#app");
const user_footer = createApp(UserFooter).mount("#user_footer");
const user_header = createApp(UserHeader).mount("#user_header");
const atelier_page = createApp(AtelierPage).mount("#atelier_page");
const metion_legal_page = createApp(MentionLegalPage).mount("#mention_legal_page");
const tabs_wrapper = createApp(TabsWrapper).mount('#tabs_wrapper')
const atelier_tab = createApp(AtelierTab).mount('#atelier_tab')
