<template lang="">
  <div class="">
    <div class='atelier-titre-layout'>
      <div class="vector">
        <h1>ATELIERS D’ECRITURE</h1>
      </div>
    </div>
    <div class='intro-layout'>
      <img class='image-layout' src="../../assets/images/photo.jpeg" alt="">
      <div>
        <p class='text'><span class='bolder'>Si on n’ose pas écrire, c’est qu’écrire n’est pas un acte anodin.</span> Ecrire suppose de surmonter des peurs, d’affronter des émotions parfois douloureuses, de se confronter à des difficultés plus techniques quand on entreprend un projet sur le long terme. </p>
        <p class='text-orange'>Écrire surprend. C'est un chemin d'une grande richesse, qui peut nécessiter un accompagnement afin d'en explorer au mieux tous les potentiels.</p>
      </div>
    </div>
    <div>
      <div class='titre-layout'>
        <h2 class='titre'>LES ATELIERS COLLECTIFS</h2>
        <p class='sub-text'>Explorez l'écriture de soi avec les ateliers en groupe, en présentiel ou en visio.</p>
        <p class='sub-text'>Osez vous lancer et découvrir le pouvoir des mots.</p>
      </div>
      <div class='tabs-layout'>
        <TabsWrapper :background-color='backgroundColor' >
          <AtelierTab class="gypsy" title="01. FONCTIONNEMENT">
            <div class='tab-layout'>
              <h3 class='title-tab'>1. Accueil</h3>
              <p class='text-tab'>Présentation des participants, présentation de la thématique de la séance, lecture d’un texte pouvant servir le propos et proposition d’une consigne d’écriture.</p>
              <h3 class='title-tab'>2. Temps d’écriture</h3>
              <p class='text-tab'>Environ 30 à 45 minutes.</p>
              <p class='text-tab'>Les consignes peuvent prendre une forme ludique (jeux autour de l’écriture), ou faire appel aux sens (écrire à partir d’une photo, d’une musique) les formes sont variées (liste, poésie, haiku, lettre) et adaptées en fonction du groupe.</p>
              <h3 class='title-tab'>3. Temps de lecture et de partage</h3>
              <p class='text-tab'>Lecture du texte à haute voix. Ecoute et retours bienveillants des participants.</p>
            </div>
          </AtelierTab>
          <AtelierTab class="terra-1" title="02. OBJECTIFS">
            <div class='tab-layout'>
              <ul class='list-objectifs'>
                <li class='text-tab'>Oser se lancer dans un processus créatif</li>
                <li class='text-tab'>Se laisser surprendre par les mots qui surgissent</li>
                <li class='text-tab'>Prendre du plaisir à produire un texte, même court</li>
                <li class='text-tab'>Se nourrir des retours bienveillants</li>
                <li class='text-tab'>S’enrichir de l’écoute des textes d’autres participants</li>
                <li class='text-tab'>Partager</li>
              </ul>
            </div>
          </AtelierTab>
          <AtelierTab class="calico-rose" title="03. NIVEAU DES PARTICIPANTS">
            <div class='tab-layout'>
              <p class='title-tab'>Les ateliers s’adressent à tous, aucun pré requis n’est nécessaire, aucune expérience de l’écriture.</p>
              <p class='text-tab'>L’objectif n’est pas d’écrire « bien » mais d’écrire tout court. </p>
              <p class='text-tab'>De trouver vos mots, ceux qui diront quelque chose de vous, de votre histoire, de vos désirs, de vos douleurs.</p>
              <p class='text-tab'>De faire entendre votre voix.</p>
              <p class='text-tab'>De faire émerger les histoires que vous portez.</p>
            </div>
          </AtelierTab>
          <AtelierTab class="peach-poppy" title="04. INFOS PRATIQUES">
            <div class='tab-layout'>
              <ul class='list-objectifs'>
                <li class='text-tab'>Les ateliers ont lieu le <span class='bolder'>mercredi</span></li>
                <li class='text-tab'>Une séance <span class='bolder'>tous les 15 jours</span>, de <span class='bolder'>18h à 20h30</span></li>
                <li class='text-tab'>En <span class='bolder'>présentiel </span>(Clermont Ferrand) ou <span class='bolder'>en visio</span></li>
                <li class='text-tab'>Prix : 25€</li>
                <li class='text-tab'>Réservation par mail</li>
              </ul>
              <div class="button-header-layout">
                <a class ="contact-button" href="mailto:secrireavecflorencedubois@gmail.com"><img src="../../assets/images/logo-letter.svg" alt=""> Contactez-moi</a>
              </div>
            </div>
          </AtelierTab>
        </TabsWrapper>
      </div>
    </div>

    <div class='other-atelier-layout'>
      <a id='aller-plus-loin'></a>
      <h2 class='title'>ALLER PLUS LOIN...</h2>
      <div class='other-atelier-section'>
        <h3 class='other-atelier-title'>L'ACCOMPAGNEMENT INDIVIDUEL</h3>
        <div class='vertical-bar'></div>
        <div class='description-section'>
          <p class='other-atelier-description'>
            Profitez d'un soutien sur mesure et confidentiel, adapté spécialement à votre parcours. Atteignez vos objectifs en toute confiance avec un accompagnement individualisé et bienveillant.
          </p>
          <p class='other-atelier-description bolder'>L’accompagnement individuel est possible sur demande par mail.</p>
        </div>
      </div>
      <div class='other-atelier-section'>
        <h3 class='other-atelier-title'>SUIVI DE MANUSCRIT</h3>
        <div class='vertical-bar'></div>
        <div class='description-section'>
          <p class='other-atelier-description'>Travaillez votre manuscrit avec un suivi personnalisé. Obtenez des retours détaillés et constructifs pour peaufiner votre écriture et donner vie à votre oeuvre littéraire.</p>
          <p class='other-atelier-description bolder'>Racontez-moi votre projet ou envoyez-moi les premières pages. En fonction, je vous proposerai un devis.</p>
        </div>
      </div>
      <div class="button-header-other-atelier">
      <a class ="contact-button" href="mailto:secrireavecflorencedubois@gmail.com"><img src="../../assets/images/logo-letter.svg" alt=""> Contactez-moi</a>
      </div>
    </div>

  </div>
</template>
<script>
  import TabsWrapper from './TabsWrapper.vue'
  import AtelierTab from './AtelierTab.vue'

export default {
  components: {
    TabsWrapper,
    AtelierTab,
  },
  data() {
    return {
      backgrondColor: 'red'
    }
  }
}



</script>
