<template>
<div class="tabs">
  <ul class="tabs-header" :style="`background-color: ${colors[i]}`" >
    <li
    v-for="(title, i) in tabTitles"
    :key="title"
    :class="{ selected: title == selectedTitle }"
    @click="selectedTitle = title"
    :style="`background-color: ${colors[i]}` "
    >
    {{ title }}
  </li>
</ul>
<slot></slot>
</div>
</template>

<script>
import { ref, provide, computed } from 'vue'

export default {
  props: {
    title: String,
    backgroundColor: String,
  },
  setup(props, { slots }) {
    const tabTitles = ref(slots.default().map((tab) => tab.props.title))
    const selectedTitle = ref(tabTitles.value[0])


    const colors = ["#DC9B92", "#AC5949", '#E5C2B0', '#DEA9A9']

    provide("selectedTitle", selectedTitle)

    const tabBackgroundColor = computed(() => {
      const selectedTab = slots.default().find((tab) => tab.props.title === selectedTitle.value);
      return selectedTab ? selectedTab.props.backgroundColor : props.backgroundColor;
    });

    console.log(props, 'ccc', tabBackgroundColor.value)

    return {
      selectedTitle,
      tabTitles,
      tabBackgroundColor,
      colors
    }
  }
}
</script>

<style scoped>
  .tabs {
    max-width: 80%;
    margin: 0 auto;
  }

  .tabs-header {
    list-style: none;
    padding: 0;
    display: flex;
    margin: 0;

  }

  .tabs-header li {
    width: 100%;
    text-align: center;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 80px 80px 0px 0px;
    color: var(--Blanc, #FFF);
    font-family: Bayon;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 2px;
  }



</style>
