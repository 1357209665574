<template lang="">
  <div>
    <div class="header-home">
      <div class="button-header-layout">
        <a class ="contact-button-home" href="mailto:secrireavecflorencedubois@gmail.com"><img src="../../assets/images/logo-letter.svg" alt=""> Contactez-moi</a>
      </div>
      <div class="header-layout">
        <div class="logo-header">
          <img src="../../assets/images/logo-s-ecrire-big.svg" alt="">
        </div>
        <div class='text-header-layout'>
          <p class="text-header">
            Découvrez le pouvoir libérateur des <strong>ateliers d’écriture</strong> où les mots deviennent des alliés <span class='bolder'>bienveillants</span> pour <span class='bolder'>apaiser</span> l'esprit et ouvrir la voie à une nouvelle expression de <span class='bolder'>soi</span>.
          </p>
        </div>
      </div>
    </div>

    <div class="atelier-home">
      <div class="titre-layout">
        <h1 class='titre-home'>ATELIERS D'ECRITURE</h1>
      </div>
      <div class='atelier-card-layout'>
        <div class="atelier-card">
          <h2 class="atelier-title">ATELIERS COLLECTIFS</h2>
          <p class="atelier-text">Explorez l'écriture de soi avec les ateliers en groupe, chaque mercredi en présentiel ou en visio. Osez vous lancer et découvrir le pouvoir des mots.</p>
          <a class ="atelier-button" href="atelier">EN SAVOIR PLUS</a>
        </div>
        <div class="atelier-card">
          <h2 class="atelier-title">ACCOMPAGNEMENT INDIVIDUEL</h2>
          <p class="atelier-text">Profitez d'un soutien sur mesure et confidentiel, adapté spécialement à votre parcours. Atteignez vos objectifs en toute confiance avec un accompagnement individualisé et bienveillant.</p>
          <a class ="atelier-button" href="atelier/#aller-plus-loin">EN SAVOIR PLUS</a>
        </div>
        <div class="atelier-card">
          <h2 class="atelier-title">SUIVI DE MANUSCRIT</h2>
          <p class="atelier-text">Travaillez votre manuscrit avec un suivi personnalisé. Obtenez des retours détaillés et constructifs pour peaufiner votre écriture et donner vie à votre oeuvre littéraire.</p>
          <a class ="atelier-button" href="atelier/#aller-plus-loin">EN SAVOIR PLUS</a>
        </div>
      </div>

    </div>
      <div class="who-I-am-home">
          <div class="who-I-am-layout">
            <img class='who-I-am-image' src="../../assets/images/photo_florence.png" alt="">
            <div class='who-I-am-text-layout'>

              <h1 class="who-I-am-title">QUI SUIS-JE ?</h1>
              <p class="who-I-am-text">Passionnée de littérature, j'ai grandi en Auvergne, mais ce sont les livres qui m'ont élevée. J'écris depuis quelques années, principalement des romans et j’ai participé à de très nombreux ateliers d’écriture.</p>
              <p class="who-I-am-text">Je suis également <span class="bolder">formée à l’animation d’ateliers d’écriture thérapeutique.</span></p>
              <p class="who-I-am-text">Les mots occupent aussi une grande place dans mon activité professionnelle puisque j'ai choisi après des études de médecine de m’orienter vers la psychiatrie.</p>
              <p class="who-I-am-text">Je crois, plus qu'en tout autre chose, <span class="bolder">au pouvoir des mots.</span> Ceux qu'on entend peuvent blesser, anéantir dans certains cas, ceux qu'on lit peuvent consoler, sauver de temps en temps, ceux qu'on écrit permettent de s'approprier son histoire, de penser l'impensable, d'approcher l'inapprochable, de mettre du sens dans nos vies, de se faire exister. </p>
              <p class="who-I-am-text"><span class='bolder'>Ecrire c'est pour moi partir à la découverte de soi, pour rencontrer l'Autre.</span> </p>
              <a class ="contact-button" href="mailto:secrireavecflorencedubois@gmail.com"><img src="../../assets/images/logo-letter.svg" alt=""> Contactez-moi</a>
            </div>
          </div>
      </div>
    <div class="quote-home">
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <!-- Your Swiper slides go here -->
          <swiper
            :modules="modules"
            :slides-per-view="1"
            :space-between="50"
            :pagination="{ clickable: true }"
            :autoplay="{
              delay: 10000,
              disableOnInteraction: false,
            }"
            @swiper="onSwiper"
            @slideChange="onSlideChange"
          >

            <swiper-slide>
              <div class='quote-layout'>
                <h3 class="quote-text">“Ecrire, je ne peux pas. Personne ne peut. Ecrire on ne peut pas. Il faut le dire. On ne peut pas. Et pourtant on écrit”</h3>
                <p class="quote-author">Marguerite Duras</p>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class='quote-layout'>
                <h3 class="quote-text">“L’écriture advient, sauveur inespéré pour celui qui ne peut dire à personne l’horreur qui le taraude”</h3>
                <p class="quote-author">Pierre Georges Despierre</p>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class='quote-layout'>
                <h3 class="quote-text">“Les mots savent de nous des choses que nous ignorons d’eux”</h3>
                <p class="quote-author">Rene Char</p>
              </div>
            </swiper-slide>
          </swiper>
        </div>
        <!-- Add Swiper Navigation
        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div> -->
      </div>
    </div>
  </div>
</template>
<script>
  import { Autoplay, Navigation, Pagination, A11y } from 'swiper/modules';

  import { Swiper, SwiperSlide } from 'swiper/vue';

  import 'swiper/css';
  import 'swiper/css/navigation';
  import 'swiper/css/pagination';


  export default {
    components: {
      Swiper,
      SwiperSlide,
    },
    data() {
      return {
        count: 0,
      }
    },
    setup() {
      const onSwiper = (swiper) => {
        console.log(swiper);
      };
      const onSlideChange = () => {
        console.log('slide change');
      };
      return {
        onSwiper,
        onSlideChange,
        modules: [ Autoplay, Navigation, Pagination, A11y],
      };
    },
  }
</script>
<style lang="">

</style>
